.card {
   @apply h-full rounded-[14px] flex flex-col bg-white lg:bg-transparent transition duration-300 w-full pb-4 max-w-full relative overflow-hidden shadow-xl lg:shadow-none;
   @apply w-full max-w-[390px];
   /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08); */
   .img {
      @apply w-full object-cover rounded-lg min-h-full !h-[360px];
   }
   .title {
      @apply font-semibold text-lg text-primary-color block hover:underline mt-2 h-[52px] mb-2  line-clamp-2 break-words;
   }
   .img-container {
      @apply overflow-hidden mb-2 relative !h-[360px] rounded-t-default;
      span,
      div {
         @apply !h-[360px];
      }
   }

   &.small {
      @apply border-none h-auto bg-white;
      .img,
      .img-container {
         @apply !h-[200px] rounded-default;
      }
      .title {
         @apply mb-1 min-h-0;
      }
   }
}
